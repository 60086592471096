<template>
  <div class="auth">
    <div class="wrapper signup">
      <div class="content">
        <div class="logo">
          <h1>
            <app-i18n code="app.title"></app-i18n>
          </h1>
        </div>

        <el-form
          :model="model"
          :rules="rules"
          @submit.prevent.native="doSubmit"
          class="form"
          label-position="right"
          label-width="120px"
          ref="form"
        >
          <el-form-item
            :label="fields.academicTitle.label"
            :prop="fields.academicTitle.name"
            :required="fields.academicTitle.required"
          >
            <el-input
              auto-complete="off"
              ref="focus"
              type="text"
              v-model="model[fields.academicTitle.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.firstName.label"
            :prop="fields.firstName.name"
            :required="fields.firstName.required"
          >
            <el-input
              auto-complete="off"
              ref="focus"
              type="text"
              v-model="model[fields.firstName.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.lastName.label"
            :prop="fields.lastName.name"
            :required="fields.lastName.required"
          >
            <el-input
              auto-complete="off"
              ref="focus"
              type="text"
              v-model="model[fields.lastName.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.email.label"
            :prop="fields.email.name"
            :required="fields.email.required"
          >
            <el-input
              auto-complete="off"
              ref="focus"
              type="email"
              v-model="model[fields.email.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.phoneNumber.label"
            :prop="fields.phoneNumber.name"
            :required="fields.phoneNumber.required"
          >
            <el-input
              auto-complete="off"
              prefix-icon="el-icon-fa-plus"
              ref="focus"
              type="text"
              v-model="model[fields.phoneNumber.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.faculty.label"
            :prop="fields.faculty.name"
            :required="fields.faculty.required"
          >
            <el-col>
              <app-faculty-autocomplete-input
                :fetchFn="fields.faculty.fetchFn"
                :mapperFn="fields.faculty.mapperFn"
                :filter="filter"
                mode="multiple"
                v-model="model[fields.faculty.name]"
              ></app-faculty-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.location.label"
            :prop="fields.location.name"
            :required="fields.location.required"
          >
            <el-col>
              <app-location-autocomplete-input
                :filter="filter"
                :fetchFn="fields.location.fetchFn"
                :mapperFn="fields.location.mapperFn"
                mode="multiple"
                v-model="model[fields.location.name]"
              ></app-location-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.centerInstitution.label"
            :prop="fields.centerInstitution.name"
            :required="fields.centerInstitution.required"
            ref="centerInstitutionFormField"
          >
            <el-col>
              <app-center-institution-autocomplete-input
                :fetchFn="fields.centerInstitution.fetchFn"
                :mapperFn="fields.centerInstitution.mapperFn"
                :filter="filter"
                mode="multiple"
                v-model="model[fields.centerInstitution.name]"
              ></app-center-institution-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.outpatientClinic.label"
            :prop="fields.outpatientClinic.name"
            :required="fields.outpatientClinic.required"
            ref="outpatientClinicFormField"
          >
            <el-col>
              <app-outpatient-clinic-autocomplete-input
                :fetchFn="fields.outpatientClinic.fetchFn"
                :mapperFn="fields.outpatientClinic.mapperFn"
                :filter="filter"
                mode="multiple"
                v-model="model[fields.outpatientClinic.name]"
              ></app-outpatient-clinic-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item class="form-buttons">
            <el-button
              :loading="loading"
              class="w-100"
              native-type="submit"
              type="primary"
            >
              <app-i18n code="auth.requestRegistration"></app-i18n>
            </el-button>
          </el-form-item>

          <div class="other-actions">
            <router-link :to="{ path: '/auth/signin' }">
              <el-button type="text">
                <app-i18n code="auth.alreadyHaveAnAccount"></app-i18n>
              </el-button>
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

const formSchema = new FormSchema([
  fields.email,
  fields.academicTitle,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.faculty,
  fields.location,
  fields.centerInstitution,
  fields.outpatientClinic,
  fields.updatedAt,
]);

export default {
  name: 'app-signin-page',

  props: ['saveLoading'],

  data() {
    return {
      rules: formSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    formSchema() {
      return formSchema;
    },

    fields() {
      return fields;
    },

    filter: function () {
      let faculty = [];
      let location = [];
      if (this.model.faculty) {
        faculty = this.model.faculty.map((f) => {
          return f.label;
        });
      }
      if (this.model.location) {
        location = this.model.location.map((f) => {
          return f.label;
        });
      }

      // at this point we add watchers to avoid triggers diring data initialization
      this.$watch('model.faculty', this.doResetFormDependentFields);
      this.$watch('model.location', this.doResetFormDependentFields);

      return {
        faculty: faculty,
        location: location,
      };
    },
  },

  async created() {
    this.model = this.formSchema.initialValues();
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
      doCreateUserAfterSignup: 'auth/doCreateUserAfterSignup',
    }),

    doResetFormDependentFields() {
      this.model.centerInstitution = [];
      this.model.outpatientClinic = [];
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      const values = this.formSchema.cast(this.model);

      await this.doCreateUserAfterSignup(values);
    },
  },
};
</script>